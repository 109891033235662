<template>
  <section class="product">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <div class="container">
      <el-tabs :tab-position="tabPosition" class="tab" v-model="editableTabsValue">
        <el-tab-pane class="product_my" name="product_my" label="产品中心" :disabled="isDisabled"></el-tab-pane>
        <el-tab-pane class="product_wen" name="product_wen" label="变电站综合监控平台" :lazy="lazy">
          <section class="article">
            <h4 class="title">智能变电站辅助系统综合监控平台</h4>
            <section v-for="(item, index) in data1" :key="index" class="product_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
              </section>
            </section>
          </section>
        </el-tab-pane>
        <el-tab-pane class="product_hong" name="product_hong" label="红外重症监护平台" :lazy="lazy">
          <section class="article">
            <h4 class="title">特高压重症（红外）监护平台</h4>
            <section v-for="(item, index) in data2" :key="index" class="product_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
              </section>
            </section>
          </section>
        </el-tab-pane>
        <el-tab-pane class="product_zhi" name="product_zhi" label="微环境状态监测平台" :lazy="lazy">
          <section class="article">
            <h4 class="title">微环境（温湿度、积水）状态监测平台</h4>
            <section v-for="(item, index) in data3" :key="index" class="product_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
              </section>
            </section>
          </section>
        </el-tab-pane>
        <el-tab-pane class="product_ai" name="product_ai" label="AI视觉“精确识别”" :lazy="lazy">
          <section class="article">
            <h4 class="title">通过AI视觉“精确识别”技术为电力系统提供设备运行状态精确检测服务和物资管理服务</h4>
            <section v-for="(item, index) in data4" :key="index" class="product_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
              </section>
            </section>
          </section>
        </el-tab-pane>
        <el-tab-pane class="product_service" name="product_service" label="为变电站提供服务" :lazy="lazy">
          <section class="article">
            <h4 class="title">通过信息通讯、集成技术、物联网技术和各类型传感器为变电站提供安全运行、状态监测和设备辅控方面的服务</h4>
            <section v-for="(item, index) in data5" :key="index" class="product_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
              </section>
            </section>
          </section>
        </el-tab-pane>
        <el-tab-pane class="product_charging" name="product_charging" label="充电桩主动防御装置" :lazy="lazy">
          <section class="article">
            <h4 class="title">充电桩供电主动防御装置</h4>
            <section v-for="(item, index) in data6" :key="index" class="product_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
              </section>
            </section>
          </section>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>
  import imageBanner from '../components/ImageBanner.vue'
  export default {
    name: 'product',
    components: {imageBanner},
    data () {
      return {
        tabPosition: 'left',
        isDisabled: true,
        editableTabsValue : 'product_wen',
        lazy: true,
        data1: [
          {title: '平台开发导则', content: [
            {tit:null,con:'变电站辅助设备监控系统采用开放式系统，系统设备配置和功能满足无人值班技术要求。',url:null},
            {tit:null,con:'变电站辅助设备监控系统满足全站安全运行要求。系统后台设备按全站最终规模配置，前端设备按最终建设规模配置。',url:null},
            {tit:null,con:'变电站辅助设备监控系统具备变电站各子系统逻辑模型展示能力，可以正确读取、显示、组织相关模型和实时信息数据。',url:null}
          ]},
          {title: '平台系统架构', content: [
            {tit:null,con:'变电站辅助设备监控系统由视频监控子系统、技防报警子系统、环境监测子系统、灯光控制子系统、火灾报警子系统、水冷却子系统（地下变电站）组成，实现对站内安防、消防、视频、环境监测等辅助设备的监视与控制。',url:null},
            {tit:null,con:'技防报警子系统、火灾报警子系统、水冷却子系统设置独立主机与辅控后台系统通信，其他子系统均不设置独立主机，由辅控后台系统集成。',url:null},
            {tit:'(1) 网络架构',con:'',url:null},
            {tit:null,con:'变电站辅助设备监控系统（虚线框内）各子系统连接、和其他系统的连接见图2-1。',url:null},
            {tit:null,con:'',url:require('../assets/images/product/1.png')},
            {tit:null,con:'变电站辅助设备监控系统与辅助设备进行通信，采集一次设备、消防、安防、环境监测等信息，经过分析和处理后进行可视化展示，并将数据存入综合应用服务器。Ⅱ区数据通信网关机从综合应用服务器获取Ⅱ区数据和模型等信息，与调度（调控）中心进行信息交互，提供信息查询和远程浏览服务；',url:null},
            {tit:null,con:'技防报警子系统、火灾报警子系统、水冷却子系统通过独立主机与辅控后台系统通信，视频监控、环境监测前端智能设备、灯光控制系统以通信接口接入辅控后台系统。站内所有辅助设备实现数据汇集到变电站辅助设备监控系统后，通过II区通信网关机走调度数据专网将信息上送到调度主站。',url:null},
            {tit:'(2) 通信接口定义',con:'',url:null},
            {tit:null,con:'1. 站内视频监控前端设备通过B接口将视频监控数据上送到变电站辅助设备监控系统后台；',url:null},
            {tit:null,con:'2. 变电站辅助设备监控系统后台通过A接口将视频监控数据上送到其它视频主站；',url:null},
            {tit:null,con:'3. 环境监测部分中的传感器如温湿度传感器、风速传感器、SF6探测器等通过RS485方式接入变电站辅助设备监控系统后台；水浸传感器通过硬接点方式接入变电站辅助设备监控系统后台；',url:null},
            {tit:null,con:'4. 变电站辅助设备监控系统监控后台与技防报警子系统、火灾报警子系统、水冷却子系统采用IEC61850进行通信，实现设备控制、监视和联动；',url:null},
            {tit:null,con:'5. 具备和照明、暖通、给排水等系统的通信接口；',url:null},
            {tit:null,con:'6. 变电站辅助设备监控系统后台通过IEC61850与站内一体化监控系统通信；',url:null},
            {tit:null,con:'7. 变电站辅助设备监控系统可通过IEC101、IEC104与调度主站通信，通过DLT476规约上送文本信息到调度主站。',url:null},
          ]},
          {title: '变电站辅助设备监控系统后台', content: [
            {tit:'(1) 辅控系统后台硬件组成',con:'',url:null},
            {tit:null,con:'变电站辅助设备监控系统后台（以下简称“后台系统”）配置1台独立主机，1台综合电源，1台环境数据采集单元（含SF6带显示监测器），1台灯光智能控制单元，交换机按最终规模配置并预留一定数量的接口。',url:null},
            {tit:'(2) 辅控系统后台软件功能',con:'',url:null},
            {tit:null,con:'后台系统包含图形显示、视频监控、环境监测、技防报警、消防报警、水冷却系统等模块组成。',url:null},
            {tit:null,con:'后台系统除实现智能变电站辅助控制系统设计技术规范、智能变电站辅助系统综合监控平台通用技术规范要求中提及的功能外，还需实现下述功能。',url:null},
            {tit:'2.1.实时监视控制功能',con:'',url:null},
            {tit:null,con:'1. 可实现安全防范、防火、防人为事故，实现被监视目标的实时图像及报警信号上传到主站端，能实现远方控制功能。具备信号远方复归功能；',url:null},
            {tit:null,con:'2. 可实现操作人员的权限管理，保证控制的唯一性；',url:null},
            {tit:null,con:'3. 可实现当地或远程布防和撤防，可按照事先制定的策略自动进行布防和撤防，也可通过电子地图进行布防和撤防；',url:null},
            {tit:null,con:'4. 可实现跟踪监视运行人员功能，当运行人员对某一设备进行操作时，摄像机应自动调整到该区域，并能启动录像装置录像；',url:null},
            {tit:null,con:'5. 可能实现摄像头的启停控制，可实现、空调电源、风机、水泵、照明设备启停；',url:null},
            {tit:null,con:'6. 可实现火灾报警子模块的火灾报警功能，火灾发生时，可显示发生火警的地点，并发出声光报警信号；',url:null},
            {tit:null,con:'7. 可实现环境监测，可显示温度、湿度、SF6浓度、水浸报警等。站内辅控系统可提供远方中心站控制接口，可实现远方启停站内潜水泵；',url:null},
            {tit:null,con:'8. 可实现视频、环境、给排水、暖通、技防、消防、水冷等各辅助子系统逻辑图SVG格式. 展示及实时信号关联展示功能，并将相关信息按要求传送变电站数字孪生系统。',url:null},
            {tit:'2.2.联动功能',con:'',url:null},
            {tit:null,con:'1. 可实现用户自定义的设备联动，包括照明、暖通、消防、火灾、环境监测等相关设备联动；',url:null},
            {tit:null,con:'2. 夜间或照明不良启动摄像头摄像，必要时可联动辅助灯光、开启照明灯；',url:null},
            {tit:null,con:'3. 发生火灾时，可联动报警设备所在区域的摄像机跟踪拍摄火灾情况，自动解锁房间门禁、自动切断风机电源、空调电源；',url:null},
            {tit:null,con:'4. 发生非法入侵时，可联动报警设备所在区域的摄像机，并启动报警功能；',url:null},
            {tit:null,con:'5. 配电装置室SF6浓度超标时，可自动启动相应的风机，并启动报警功能，必要时可联动相应区域内的摄像机；',url:null},
            {tit:null,con:'6. 发生水浸时，可自动启动相应的水泵排水；',url:null},
            {tit:null,con:'7. 可实现对室内环境温度、湿度的实时采集。可自动启动或关闭通风空调系统；',url:null},
            {tit:null,con:'8. 视频监控子系统具有与火灾自动报警子模块联动的功能。发生火灾时，火灾发生点处附近的摄像机可跟踪拍摄火灾情况，监视器画面可自动切换，以显示火灾情况。',url:null},
            {tit:'2.3.报警功能',con:'',url:null},
            {tit:null,con:'1. 报警类别可区分防盗报警、火灾报警、环境异常报警。报警可根据需要进行分级，报警信号、报警内容可在任何画面自动显示。当发生报警时，相应录像装置可自动进行存盘录像，并同时传送报警信息和相关图像，可自动在电子地图上提示报警位置及类型；',url:null},
            {tit:null,con:'2. 可处理多事件多点报警的能力，报警信息不应丢失和误报；',url:null},
            {tit:null,con:'3. 可在报警视窗内提供报警信号的详细信息；',url:null},
            {tit:null,con:'4. 报警时可提供语音报警和电话、传呼报警等多种方式；',url:null},
            {tit:null,con:'5. 可通过弹出菜单等方式设置报警联动的摄像机，可以联动摄像机的不同预置位；',url:null},
            {tit:null,con:'6. 所有报警信息均可查询，必要时可打印输出。',url:null},
            {tit:'2.4.视频显示功能',con:'',url:null},
            {tit:null,con:'1. 可实现实时图像监视，视频图像大小可随意可调；',url:null},
            {tit:null,con:'2. 可实现主辅视频及辅助视频端口的本地输出；',url:null},
            {tit:null,con:'3. 可实现1/4/9/16画面预览，预览通道顺序可调；',url:null},
            {tit:null,con:'4. 可实现预览分组切换、手动切换或自动轮巡预览，自动轮巡周期可设置；',url:null},
            {tit:null,con:'5. 可实现预览的电子放大。可支持屏蔽指定的预览通道；',url:null},
            {tit:null,con:'6. 可实现预置点、巡航路径及轨迹。云台控制时，可实现鼠标点击放大、鼠标拖动跟踪功能。',url:null},
            {tit:'2.5.图像储存回放功能',con:'',url:null},
            {tit:null,con:'1. 录像触发模式包括手动、定时、报警、移动侦测、动测或报警、动测和报警等；',url:null},
            {tit:null,con:'2. 每天可设定多个录像时间段，不同时间段的录像触发模式可独立设置；',url:null},
            {tit:null,con:'3. 可移动侦测录像、报警录像、动测和报警录像、动测或报警录像的预录及延时，定时和手动录像的预录；',url:null},
            {tit:null,con:'4. 可按事件查询录像文件。可实现录像文件的锁定和解锁；',url:null},
            {tit:null,con:'5. 可在回放时对任意区域进行局部电子放大。可实现回放时的暂停、快放、慢放、前跳、后跳，支持鼠标拖动定位。可实现多路同步回放，可通过资源配置加以调整。',url:null},
            {tit:'2.6.遥控操作的智能验证',con:'',url:null},
            {tit:null,con:'当一体化监控系统对某个设备发出遥控指令后，系统通过智能视频分析技术，可以对主变压器、断路器、隔离开关、接地刀闸等设备的各项指标进行智能分析，获取设备的状态参数，智能判断设备状态与一体化监控指令是否一致，并可以实时报警。',url:null},
            {tit:'2.7.事件记录及查询',con:'',url:null},
            {tit:null,con:'可以对设备的监视结果进行记录，记录的内容包括变电站设备的运行状态、故障描述信息、故障发现时间、故障处理情况等信息，并可对记录的历史信息进行查询。',url:null},
            {tit:'2.8.电子地图功能',con:'',url:null},
            {tit:null,con:'1. 支持在地图上对(设备摄像机、门禁、灯控、电子围栏等)状态进行监视、控制操作和管理；',url:null},
            {tit:null,con:'2. 支持对地图进行放大、缩小和漫游功能；',url:null},
            {tit:null,con:'3. 支持报警事件联动，当报警发生时，支持在地图上直接对变电站进行管理；',url:null},
            {tit:null,con:'4. 在电子地图上自动推出报警地点实时画面；',url:null},
            {tit:null,con:'5. 显示摄像机、报警器、温湿度等视频监控设备的位置及环境监测量的数值；',url:null},
            {tit:null,con:'6. 电子地图按变电站、电压等树形分层结构进行直观浏览；',url:null},
            {tit:null,con:'7. 各级电子地图中显示摄像机等设备的分布和报警器设备布防、撤防、报警的状态；',url:null},
            {tit:null,con:'8. 可以对电子地图自行编辑、配置，设置地图，设置摄像机、报警器的位置；',url:null},
            {tit:null,con:'9. 可以在电子地图浏览视频图像；',url:null},
            {tit:null,con:'10. 地图信息保存在数据库中。',url:null},
          ]},
          {title: '环境监测子系统', content: [
            {tit:'（1）系统功能及构成',con:'',url:null},
            {tit:null,con:'环境监测系统通过各类传感器的部署来监视全变电站的运行环境状况，使变电站设备处于良好的运行环境中。',url:null},
            {tit:null,con:'站端硬件技术及系统功能满足智能变电站辅助控制系统设计技术规范、智能变电站辅助系统综合监控平台通用技术规范中相关要求。',url:null},
            {tit:'（2）通信接口及规约',con:'',url:null},
            {tit:null,con:'温湿度传感器、SF6泄露传感器、水浸传感器、液位传感器等前端设备采用RS485与辅控系统后台通信，按需配置SF6监测值显示器。',url:null},
            {tit:'（3）前端硬件布置',con:'',url:null},
            {tit:null,con:'1. 二次设备室、电容器室、站用变室、开关室等重要设备间，面积小于20平方米，每个房间布置1台温湿度监测器；面积大于20平方米，各布置2台温湿度检测器；',url:null},
            {tit:null,con:'2. 根据电缆层面积大小，配置若干水浸传感器；',url:null},
            {tit:null,con:'3. 每台空调配置1个空调控制器（根据空调型号选配）；',url:null},
            {tit:null,con:'4. 每路风机配置1个风机控制器。',url:null},
            {tit:'（4）电源供给',con:'',url:null},
            {tit:null,con:'1. 前端设备电源在屏柜内集中通过配电回路配送，机柜中安装配电接线端子，每路电源都有标签详细说明，便于维护、检修；',url:null},
            {tit:null,con:'2. 前端探测器包括温湿度、水浸、风速、SF6探测器、空调控制器的供电由柜内综合电源提供；综合电源与屏柜内配电器联接。',url:null},
            {tit:'（5）光/电缆选择',con:'',url:null},
            {tit:null,con:'温度传感器、湿度传感器、SF6泄露传感器、水浸传感器到环境监测子系统采用阻燃屏蔽电缆ZR-RVVP02-4*1.0实现信号的传输和供电。考虑到信号传输的实时性要求，建议邻近布置的多个传感器合并为一路实现信号传输。',url:null},
          ]},
          {title: '灯光控制子系统', content: [
            {tit:'(1)系统功能及构成',con:'',url:null},
            {tit:null,con:'在采用室内网络快球且照度条件不满足摄像机照度要求时需补充灯光照明，跟房间照明联动，根据现场实际需求由辅控后台通过灯光智能控制单元发送控制信号。',url:null},
            {tit:null,con:'固定式摄像机采用单独配置补光灯方式满足摄像机对照度的要求。',url:null},
            {tit:null,con:'灯光控制子系统中站端硬件技术及系统功能满足智能变电站辅助控制系统设计技术规范、智能变电站辅助系统综合监控平台通用技术规范中相关要求。',url:null},
            {tit:'(2)通信接口及规约',con:'',url:null},
            {tit:null,con:'灯光智能控制单元采用硬结点方式实现对室内照明的联动控制。',url:null},
            {tit:'(3)前端硬件布置',con:'',url:null},
            {tit:null,con:'灯光控制系统无前端设备，只在遥视照明设计中在安装有室内网络快球的房间配置照明控制箱，接收辅控后台发送的硬接点信号。',url:null},
            {tit:'(4)电源供给',con:'',url:null},
            {tit:null,con:'站端系统设备应使用变电站内AC220V/50Hz交流电源；',url:null},
            {tit:'(5)光/电缆选择',con:'',url:null},
            {tit:null,con:'照明控制箱与辅控系统间连接采用阻燃屏蔽电缆ZR-RVVP4*1.0连接。',url:null},
          ]},
          {title: '其它系统接入定义', content: [
            {tit:'(1)火灾消防报警系统',con:'',url:null},
            {tit:null,con:'火灾消防报警系统优先采用IEC61850规约将信号上送到变电站辅助设备监控系统中。',url:null},
            {tit:'(2)水冷却系统',con:'',url:null},
            {tit:null,con:'若变电站为地下变电站时变电站辅助设备监控系统需负责水冷却系统的接入。',url:null},
            {tit:null,con:'水冷却系统优先采用IEC61850规约的方式将信号上送到变电站辅助设备监控系统中。',url:null},
          ]},
          {title: '带宽要求', content: [
            {tit:null,con:'辅助设备监控系统与调度（调控）中心通过安全II区上行通信，通信带宽大于4Mbps，不具备条件时，可采用2Mbps。单路视频上行网络带宽（与调度主站通信带宽）具体可参照如下执行：',url:null},
            {tit:null,con:'1. 130万像素的摄像机（720p），画面上行网络带宽不大于2Mbps；',url:null},
            {tit:null,con:'2. 200万像素的摄像机（1080p），画面上行网络带宽不大于4Mbps；',url:null},
            {tit:null,con:'3. 多画面情况下，上行网络带宽不大于4Mbps；',url:null},
            {tit:null,con:'4. 其他情况（如带宽资源丰富、1000万像素以上的摄像机等），上行网络带宽可大于4Mbps。',url:null},
          ]},
          {title: '安装要求', content: [
            {tit:null,con:'1.站端视频处理单元、综合电源、环境处理单元、网络交换机、网桥等均应组屏安装；',url:null},
            {tit:null,con:'2.前端设备（摄像机、红外对射报警器、温湿度传感器等）就近安装在配电装置设备附近；',url:null},
            {tit:null,con:'3.辅助设备监控系统所使用的通用电缆可分为；',url:null},
            {tit:null,con:'4.（铠装）阻燃屏蔽电缆（导线截面积不小于0.5mm2）；',url:null},
            {tit:null,con:'5.（铠装）阻燃电源电缆（导线截面积不小于1.0mm2）；',url:null},
            {tit:null,con:'6.屏蔽网络线、光缆等；',url:null},
            {tit:null,con:'7.非铠装电缆敷设时，电缆沟、弱电桥架、竖井内、室内墙内暗埋套穿PVC管；',url:null},
            {tit:null,con:'8.所有电缆埋地敷设时套穿热镀锌管；',url:null},
            {tit:null,con:'9.技防报警子系统、环境监测子系统、火灾报警子系统及视频监控前端设备、环境监测前端设备与一体化智能变电站辅助设备监控系统后台处于同一建筑物内且距离小于100m时宜采用六类屏蔽双绞线联接，其余采用单模光纤互联；',url:null},
            {tit:null,con:'10.电缆选择和敷设应满足GB50116、GB50217、GB50395相关要求。',url:null},
          ]},
          {title: '试验要求', content: [
            {tit:null,con:'试验标准参照国标及IEC相应标准进行。若辅助装置是第一次在国内同类变电站中采用，则应按国内标准进行试验。',url:null},
            {tit:'(1) 型式试验',con:'',url:null},
            {tit:'1.1.绝缘性能',con:'',url:null},
            {tit:null,con:'1. 绝缘电阻；',url:null},
            {tit:null,con:'2. 介质强度；',url:null},
            {tit:null,con:'3. 冲击电压。',url:null},
            {tit:'1.2.耐湿热性能',con:'',url:null},
            {tit:null,con:'根据试验条件和使用环境，在以下两种方法中选择其中一种。',url:null},
            {tit:null,con:'1. 恒定湿热；',url:null},
            {tit:null,con:'2. 交变湿热。',url:null},
            {tit:'1.3.抗电气干扰性能',con:'',url:null},
            {tit:null,con:'1. 承受辐射电磁场抗干扰的能力；',url:null},
            {tit:null,con:'2. 承受快速瞬变干扰的能力；',url:null},
            {tit:null,con:'3. 承受脉冲群干扰的能力；',url:null},
            {tit:null,con:'4. 承受静电放干扰的能力。',url:null},
            {tit:'1.4.直流电源影响',con:'',url:null},
            {tit:'1.5.静态模拟、动态模拟',con:'',url:null},
            {tit:'1.6.连通通电',con:'',url:null},
            {tit:null,con:'1. 振动（正弦）；',url:null},
            {tit:null,con:'2. 冲击；',url:null},
            {tit:null,con:'3. 碰撞。',url:null},
            {tit:'1.7.结构和外观',con:'',url:null},
            {tit:null,con:'装置机箱和摄像机等应为金属外壳，装置机箱前面有透明面罩。装置机箱和摄像机等采用防静电及防强电磁辐射干扰的措施。装置机箱和摄像机等的不带电金属部分在电气上连成一体，并可靠接地。设备满足发热元件的通风散热要求。',url:null},
            {tit:null,con:'设备有安全标志。',url:null},
            {tit:'(2) 出厂试验',con:'',url:null},
            {tit:null,con:'出厂试验验收试验的结果应与由型式试验所得到的结果相似，满足GB标准、IEC标准及本技术规范的技术要求。出厂试验也应由与型式试验相同的方法进行。',url:null},
            {tit:'(3) 现场试验',con:'',url:null},
            {tit:null,con:'至少应进行下列试验和检查：',url:null},
            {tit:null,con:'1. 直观检查；',url:null},
            {tit:null,con:'2. 通入电源检查；',url:null},
            {tit:null,con:'3. 回路检查；',url:null},
            {tit:null,con:'4. 所有接线正确性的检查及试验；',url:null},
            {tit:null,con:'5. 绝缘试验；',url:null},
            {tit:null,con:'6. 性能试验。由买方确定必要的重复试验；',url:null},
            {tit:null,con:'7. 整组试验。',url:null},
            {tit:null,con:'其他必要的试验等。',url:null},
          ]},
          {title: '附录A  110kV地下（地上联建）变电站辅助设备监控系统设备材料表', content: [
            {tit:null,con:'',url:require('../assets/images/product/2.png')},
          ]},
          {title: '附录B 110kV独立地上变电站辅助设备监控系统设备材料表', content: [
            {tit:null,con:'',url:require('../assets/images/product/3.png')},
          ]},
          {title: '附录C 35kV地下变电站辅助设备监控系统设备材料表', content: [
            {tit:null,con:'',url:require('../assets/images/product/4.png')},
          ]},
          {title: '附录D 35kV地上变电站辅助设备监控系统设备材料表', content: [
            {tit:null,con:'',url:require('../assets/images/product/5.png')},
          ]},
          {title: '附录E 35kV开关站辅助设备监控系统设备材料表', content: [
            {tit:null,con:'',url:require('../assets/images/product/6.png')},
          ]},
        ],
        data2: [
          {title: '平台开发导则', content: [
            {tit:null,con:'特高压重症监护平台采用开放式系统，系统设备配置和功能满足无人值班技术要求。',url:null},
            {tit:null,con:'特高压重症监护平台满足全站安全运行要求。系统后台设备按全站最终规模配置，前端设备按最终建设规模配置。',url:null},
            {tit:null,con:'特高压重症监护平台具备逻辑模型展示能力，可以正确读取、显示、组织相关模型和实时信息数据。',url:null},
          ]},
          {title: '平台系统架构', content: [
            {tit:null,con:'特高压重症监护平台系统由红外监控装置、网络传输装置、电源装置、后台监护系统组成，实现对站内需要监护的设备的监视。',url:null},
            {tit:null,con:'',url:require('../assets/images/product/7.png')},
          ]},
          {title: '平台功能描述（后台系统）', content: [
            {tit:null,con:'全天候监控：利用红外热像监控系统通过远端控制，无需看管而实现全天候运行。',url:null},
            {tit:null,con:'自动报警：当监测到温度异常时生成报警信息，通知相关人员查看现场情况，及时作出相应处理。',url:null},
            {tit:null,con:'实时显示：实时显示全辐射红外热图，值班人员可查看任意位置的温度。',url:null},
            {tit:null,con:'报警记录：当变电设备出现温度异常，监控后台会及时发现温度异常区域，触发报警，声光报警模块会发出报警声且软件后台会记录报警地点和区域，将异常图片存入指定文件夹下，方便后期查找调用。',url:null},
            {tit:null,con:'报警查询：可查询报警情况且对报警区域可在进行分析处理。',url:null},
            {tit:null,con:'自定义报警阈值和等级：系统可内定义4个不同报警阀值和等级，支持I/O 输出以便与消防、广播等系统联动，协助工作人员对隐患发生的紧急程度、发展势态和可能造成的危害程度作出准确评估，提升处理的有效性',url:null},
            {tit:null,con:'短信报警：当温度出现异常时，可通过短信收发模块通知相关人员注意现场情况，第一时刻了解现场情况并且做出相应处理。',url:null},
            {tit:null,con:'可对异常情况进行录制、拍照、分析，并出具专业检测报告。',url:null},
            {tit:null,con:'',url:require('../assets/images/product/8.png'), wid: '40%'},
            {tit:null,con:'',url:require('../assets/images/product/9.png'), wid: '40%'},
          ]},
          {title: '平台功能描述（前台功能）', content: [
            {tit:null,con:'红外热像仪可连续、自动、非接触采集多点温度，可用于关键设备工作状态监测，其探测芯片为焦平面阵列FPA，非制冷微热量，图像分辨率可达384×288、640×480，测温范围从-20~350℃，热灵敏度NETD≤50mk@30℃，测温点数量达110592个或更多，可自动识别并追踪最高温度位置，并根据用户报警设置对超过预警温度的设备进行报警，加上压铸铝整体护罩后，整体防护等级IP66，护罩防护等级IP67。',url:null},
          ],},
        ],
        data3: [
          {title: '平台开发导则', content: [
            {tit: null, con: '微环境状态监测平台采用开放式系统，系统设备配置和功能满足无人值班技术要求。', url: null},
            {tit: null, con: '微环境状态监测平台满足全站安全运行要求。系统后台设备按全站最终规模配置，前端设备按最终建设规模配置。', url: null},
            {tit: null, con: '微环境状态监测平台具备逻辑模型展示能力，可以正确读取、显示、组织相关模型和实时信息数据。', url: null},
          ],},
          {title: '平台系统架构', content: [
            {tit: null, con: '微环境状态监测平台系统由传感器装置、集中采集装置、Lora/NB-IoT无线传输装置、后台监护系统组成，实现对站内设备需要监测的微环境的监视。', url: null},
            {tit: null, con: '', url: require('../assets/images/product/10.png')},
          ],},
          {title: '平台功能描述（后台系统）', content: [
            {tit: null, con: '全天候监视：利用温湿度、水浸传感器无线采集系统，无需看管而实现全天候运行监视户外设备微环境。', url: null},
            {tit: null, con: '自动报警：当监测到微环境异常时生成报警信息，通知相关人员查看现场情况，及时作出相应处理。', url: null},
            {tit: null, con: '实时显示：实时显示微环境各项采集信息，值班人员可查看任意位置的温度、湿度。', url: null},
            {tit: null, con: '报警记录：当微环境采集信息异常，监控后台会及时发现微环境异常区域，触发报警，且软件后台会记录报警位置和时间，并保存在历史记录中，方便后期查找调用。', url: null},
            {tit: null, con: '报警查询：可查询报警情况且对报警信息可进行分析处理。', url: null},
            {tit: null, con: '自定义报警阈值和等级：系统可内定义不同报警阀值和等级，便于在不同环境下有针对性的设定报警阈值。', url: null},
            {tit: null, con: '', url: require('../assets/images/product/11.png')},
          ],},
          {title: '平台功能描述（前台功能）', content: [
            {tit: null, con: '温湿度采集装置、水浸传感器使用无线lora方式上传信号，可完美解决现场电源不易解决的难题，施工便捷、易于安装。', url: null},
            {tit: null, con: '安装于设备机构箱内，可精确测量机构箱内的温度和湿度或水浸情况', url: null},
            {tit: null, con: '传输方式：无线Lora', url: null},
            {tit: null, con: '传输频率：433MHz', url: null},
            {tit: null, con: '传输距离：≤500m', url: null},
            {tit: null, con: '供电方式：3.6V 锂亚电池，4000mAh', url: null},
            {tit: null, con: '待机电流：≤2uA', url: null},
            {tit: null, con: '工作寿命: 〉5年（1次/小时，如采集周期延长可增加工作寿命）', url: null},
            {tit: null, con: '发送方式：（1）采集温度/湿度波动≥设定值时发送（2）设定时间定时强制发送当前采集值；两项功能可同时启用。', url: null},
            {tit: null, con: '测量范围（温湿度）：', url: null},
            {tit: null, con: '温度： -40 ... +125°C	 (精度 1°C)', url: null},
            {tit: null, con: '湿度：0%RH ～ 99.9%RH		 (精度3 % )', url: null},
            {tit: null, con: '外壳：IP67 防水塑壳', url: null},
            {tit: null, con: '尺寸：90*50*42mm', url: null},
          ],},
        ],
        data4: [
          {title: null, content: [
            {tit: null, con: '视觉技术作为AI技术中应用最成熟的一个方向，已经催生出较为成熟的应用和巨大的商业价值。'},
            {tit: null, con: 'AI视觉精确识别目前应用最为成熟的领域毋庸置疑是人脸识别，同时还包括电商网站，搜索引擎上的根据图片来检索出商品信息，并提供推荐的商品链接。目前在电力系统的生产运行过程中，用到的视觉技术主要是用于设备测温，AI视觉识别技术主要用于故障的识别和危险行为告警。'},
            {tit: null, con: '经过我司多年的积累和与中科院等知名高校的联合研发，已经形成了一套可商用的，基于视觉AI精确识别的核心技术。'},
            {tit: null, con: '其衍生产品能够实现针对工业品（含MRO产品）的实时识别，重要物资的实时盘点，各样式表计数据实时读取等功能，可应用于零星物资领用柜、小型仓库出入库管理、重点物资的库存实时监控，圆形，数字形，柱状表，开关型等表盘的数值和状态实时读取。可以实现有一双人眼24小时帮助用户观察某一特定的场景的变化，即完美实现非介入式的设备状态信息收集和实时诊断功能，具有广阔的市场前景。'},
          ]},
          {title: '表计数值AI精确自动读取装置', content: [
            {tit: null, con: '各电压等级变电站或各类型操作室、控制室中，有大量的设备的表计需要读数和记录。'},
            {tit: null, con: '其工作量重要且繁琐，目前较为常见的方法就是通过导轨机器人进行拍照，而后回到后台后进行数据处理后，完成读数的记录，替代人工到现场巡视。而且伴随自动化程度提高，会有更多无人值守的站出现，那么表计数据的读取越发需要自动化的手段进行替代。'},
            {tit: null, con: '为什么不让设备具有数据远传的功能？这样就不用再去读表了，这里面有诸多原因，其一，大量的基础设施是几年前投运的，本身不具备远传功能，且不具备加装远程读表设备的条件；其二，有些重要的设备，即便是加装了远程读表的功能，还是需要现场去读表以及观测。'},
            {tit: null, con: '针对上述情况，我们自主研发出可多种模式下运行的进行表计数据读取和识别的AI摄像头，其优势在于AI算法强大，自学习能力强，设备安装简单，成本低。产品的主要功能点有：'},
            {tit: '（1）建立表计模型：', con: '默认的标记模型有圆标，扇形表，数字表盘，开关压板，指示灯，包括了变电站内绝大部分的需观测和记录的表计数据；如果有新的表盘需要识别，只需要在系统中上传该表盘的清晰图像和视频片段，同时说明一下读数的规则即可；'},
            {tit: '（2）无源模式：', con: '通过摄像机自带电源，进行前端拍摄，表计数据识别，识别后的数据回传，对应照片回传，数据存档备查，如果按照每天一次读表的要求，一次充电可续航3年以上；支持远程唤醒临时拍摄图片，同时支持拍摄和回传视频数据（会增加用电损耗）；'},
            {tit: '（3）有源模式：', con: '可通过POE供电的方式进行供电，可以长时间的保持相机在线，完成相关数据的采集和告警；同时也可以成为一个监控或重症监护系统的摄像头予以使用，完成相关配套的数据收集和分析工作；'},
            {tit: '（4）数据传输模式：', con: '包含了Wifi，4G/5G，RJ45为主，支持扩展LORA，ZIGBEE的低功耗模式；'},
            {tit: '（5）数据分析：', con: '表计读数趋势分析，超阈值自动告警，设备运行状态分析等可以为设备或环境提供基于连续性数据的分析和突发性情况的告警。'},
            {tit: null, con: '', url: require('../assets/images/product/12.png')},
          ]},
          {title: '零星电商物资集中收发一体机', content: [
            {tit: null, con: '生产类或非标类的零星物资往往没有赋国际码（69码）或商品码，无码可扫难以直接开展数字化管理。而通过AI视觉精确识别的技术，能够依托物资的外形、外包装等视觉维度实现对所需管理物资的扫码识别，并通过与柜门的联动，身份的验证，结合一体机配套的线上审批流程和出入库管理流程，可以实现零星物资在无人值守下的领用管理，由于产品使用视觉技术管理出入库，即：出入库时只要随手放入相应的位置或直接取出即可，相比传统物资的数字化出入库管理，其操作的便利性具有巨大优势。该产品适用于大型集团性企业高效的管理零星物资的领用。产品主要功能点有：'},
            {tit: '（1）便捷建模：', con: '提供视频和图片的方式自主建模功能，建模完毕之后，该物资即可通过视觉识别的方式纳入管理'},
            {tit: '（2）平台联动：', con: '柜体开关门与APP，PC端管理平台联动，实现物料申请，物料审批，库存进销存，领用报表等功能；'},
            {tit: '（3）物资入库：', con: '通过APP/PC端发起入库申请，审批通过后生成入库条码，经柜体识别条码后开门，随后将所需入库的物资直接放置到柜内后关门即可，系统将自扫描柜内物资库存变动的情况并于入库申请单进行复核，若不一致，将予以警示；'},
            {tit: '（4）物资出库：', con: '通过APP/PC端发起出库申请，审批通过后生成出库条码，经柜体识别后开门，随后取出所需领用的物资后关门即可，系统将自扫描柜内物资库存变动的情况并于入库申请单进行复核，若不一致，将予以警示；提货时将同时启动监控视频予以全程记录存档；值得一提的是，整个出入库的过程都极为简便，可操作性强。'},
          ]},
          {title: '生产班组库房物资管理数字化改造', content: [
            {tit: null, con: '将零星物资集中收发一体机的机制放大，可以扩展成小心专业仓的出入库管理。除了将仓库的门禁系统替换原柜门开关机制，并建立灯光联动系统，其他管理机制类似。以此可以打造一个24小时响应的无人值守的专业仓。产品的主要功能点有：'},
            {tit: '（1）便捷建模：', con: '提供视频和图片的方式自主建模功能，建模完毕之后，该物资即可通过视觉识别的方式纳入管理；'},
            {tit: '（2）平台联动：', con: '专业仓门禁与APP，PC端管理平台联动，实现物料申请，物料审批，库存进销存，领用报表等功能；'},
            {tit: '（3）库存识别机制：', con: '专业仓内分设扫码识别工作台区和自动识别区。工作台识别区主要用于识别个体较小的物资，批量处理；自动识别区为仓库大部分的可自由放置物资的开放区域，包括仓库内的地面，货架等区域，在这部分区域内的物资可被视觉系统自动识别，一旦库存发生变动，系统将自动更新库存，如有异常将自动告警；'},
            {tit: '（4）物资入库：', con: '通过APP/PC端发起入库申请，审批通过后生成入库条码，经门禁系统识别条码后开门，小型物资通过工作台逐一扫码或手工辅助输入数量后放置在仓库内非自动识别区，大型物资可直接放入自动识别区，而后关门即可，系统将自扫描仓库内物资库存变动的情况并于入库申请单进行复核，若不一致，将予以警示；'},
            {tit: '（5）物资出库：', con: '通过APP/PC端发起入库申请，审批通过后生成出库条码，经门禁系统识别条码后开门，小型物资通过工作台逐一扫码或手工辅助输入数量后进行提货，大型物资可直接提货，而后关门即可，系统将自扫描仓库内物资库存变动的情况并于入库申请单进行复核，若不一致，将予以警示；提货时将同时启动监控视频予以全程记录存档；'},
            {tit: '（6）物资出库：', con: '通过APP/PC端发起出库申请，审批通过后生成出库条码，经柜体识别后开门，随后取出所需领用的物资后关门即可，系统将自扫描柜内物资库存变动的情况并于入库申请单进行复核，若不一致，将予以警示；值得一提的是，整个出入库的过程都极为简便，可操作性强。'},
            {tit: null, con: '', url: require('../assets/images/product/13.png')},
          ]},
          {title: '生产物资库存自动盘点解决方案', content: [
            {tit: null, con: '根据视觉技术在物资精确识别方面的成功经验，可以帮助大型集团型企业对自建大型仓库的库存物资进行自动盘点，或者对其供应商协议库存备货量进行自动盘点。该解决方案的主要功能点有:'},
            {tit: '（1）便捷建模：', con: '提供视频和图片的方式自主建模功能，建模完毕之后，该物资即可通过视觉识别的方式纳入管理；'},
            {tit: '（2）仓库存储区域、货架区域的摄像头设置：', con: '平均每个摄像头至少可以看管4m*4m的面积，包括地面面积和货架纵向界面的面积，如果摄像头放置合理，单个摄像头看管面积还可以成倍扩大；'},
            {tit: '（3）频率警报：', con: '在管控区域内可以任意设置盘点的频率，发布库存异动的报表或警告。能够与客户的进销存等业务系统中的盘点模块对接，可实时通报库存异常的情况，便于用户快速的发现问题，解决问题；'},
            {tit: '（4）库存盘查：', con: '如果需要对协议供货供应商的协议库存进行备查，通过视觉的解决方案是效率最高，实操性最强的方法之一。其主要工作机制与库存盘点类似，先需要构建所需管理物资的模型，然后再存储区域安装摄像头，要求供应商把物资按规定放置在存储区域即可，系统将开展自动盘点的工作，一旦库存量低于合同约定的阀值，则自动通知用户，且予以多方告警。充足的库存才能保证用户各项工作的正常和顺利的开展。'},
          ]},
        ],
        data5: [
          {title: '智能变电站辅助设备监控系统:', content: [
            {tit: null, con: '变电站辅助设备监控系统部署在变电站，包括辅助设备监控系统主机、工作站等主要设备，集成变电站 安防、消防、动环、锁控及状态监测信息，实现辅助设备数据采集、运行监视、操作控制、对时、权限、配置、数据存储、报表以及智能联动管理，为变电站综合监控提供辅助信息支撑。'},
            {tit: null, con: '变电站辅助设备监控系统由视频监控子系统、技术防范报警子系统、环境监测子系统、灯光控制子系统、火灾报警子系统、水冷却子系统（地下变电站）组成，实现对站内安防、消防、视频、环境监测等辅助设备的监视与控制。技术防范报警子系统、火灾报警子系统、水冷却子系统设置独立主机与辅控后台系统通信，其他子系统均不设置独立主机，由辅控后台系统集成。'},
            {tit: null, con: '变电站辅助设备监控系统与辅助设备进行通信，采集一次设备、消防、安防、环境监测等信息，经过分析和处理后进行可视化展示，并将数据存入综合应用服务器。Ⅱ区数据通信网关机从综合应用服务器获取Ⅱ区数据和模型等信息，与调度（调控）中心进行信息交互，提供信息查询和远程浏览服务。'},
            {tit: null, con: '技术防范报警子系统、火灾报警子系统、水冷却子系统通过独立主机与辅控后台系统通信，视频监控、环境监测前端智能设备、灯光控制系统以通信接口接入辅控后台系统。站内所有辅助设备实现数据汇集到变电站辅助设备监控系统后，通过II区通信网关机走调度数据专网将信息上送到调度主站。'},
            {tit: null, con: '以下为完整辅控系统的拓扑架构图：'},
            {tit: null, con: '', url: require('../assets/images/product/14.png')},
            {tit: null, con: '值得一提的是，我们可以根据不同的变电站等级和用户需求，对系统进行裁剪和定制开发，以满足不同等级的变电站（包括特高压站，220站，110KV站，35KV站，P站，K站等）辅控的要求。'},
          ]},
          {title: '变电站电子安防和防恐提升平台:', content: [
            {tit: null, con: '在变电站的实际应用中，各个监控系统均各成体系、师出多门，存在设备型号复杂、损坏后不便维护、在系统相似或交叉领域重复投资等诸多问题,在业务协调、联动还存在困难。'},
            {tit: null, con: '为了进一步提高变电站智能化管理水平，适应变电站逐步向“无人值守”发展的要求，需要建立一套统一的管理维护平台——“变电站综合安防系统”来实现对各辅助系统的整合和管理，这也逐步成为国内变电站建设和改造的重要内容和发展趋势。正因如此，我司在国网上海市电力公司的要求下，开始了变电站电子安防平台的开发和建设，同时该平台的建设满足国网变电站治安反恐防范工程的各项要求。'},
            {tit: null, con: '变电站电子安防平台是我司采用自动化技术、计算机技术、网络通信技术、视频压缩技术、电子脉冲技术、RFID技术、微波探测技术、声控探测技术以及智能控制等多种技术，通过对变电站动力环境、边界、图像、门禁识别、火灾报警、安防报警实现对变电站的重点区域防控、防盗、入侵、消防、照明、采暖通风等方面的在线监视和智能预警。系统通过监测、预警和控制三种手段，为变电站的安全生产提供可靠的保障，从而解决了变电站安全运营的“在控”、“可控”等问题。所使用的到的通讯规约和标准均按照国网公司的最新标准予以实现。值得一提的是，我司熟悉变电站辅控平台的技术要求，可将两者功能进行灵活的组合，更好的满足用户的需求。平台具体的主要功能包括：'},
            {tit: null, con: '（1） 电子安防平台按照《变电站（换流站）治安反恐防范工程完善化改造方案（征求意见稿）》的具体要求予以实施；'},
            {tit: null, con: '（2） 电子安防平台以“智能控制”为核心，对全站主要电气设备、关键设备安装地点以及周围环境进行全天候的状态监视和智能控制，以满足电力系统安全生产的要求，同时，该系统可满足变电站安全警卫的要求；'},
            {tit: null, con: '（3） 电子安防平台以网络通信（IEC61850协议）为核心，完成站端音视频、环境数据、火灾报警信息、SF6、门禁以及防盗报警等数据的采集和监控，并将以上信息远传到监控中心或调度中心；'},
            {tit: null, con: '（4） 电子安防平台采用分层、分区的分布式结构，按综合安防系统省级主站系统、综合安防系统地区级主站系统和综合安防系统站端系统三级构建。每级系统必须至少包含主控中心、分控中心、和接口系统，以及WEB浏览系统等；'},
            {tit: null, con: '（5） 平台把环境、视频、火灾消防、采暖通风、照明、SF6、防盗报警、门禁等所有监控量在监控系统主界面上进行一体化显示和控制，不得分系统孤立显示和控制。系统监控应提供电子地图监控和图表监控两种监控模式，电子地图可以实现省、市以及变电站的分级分层显示；'},
            {tit: null, con: '（6） 站端设备能够脱机运行，在网络断线、服务器故障等情况下站端设备依然能够实现正常运行、联动报警、设备控制以及记录存储等；'},
            {tit: null, con: '（7） 平台可以设置平台内的动力环境监控子系统、视频监控子系统、消防子系统、空调及设备控制子系统、SF6监测报警子系统、防盗报警子系统、门禁子系统等各子系统之间进行联动；还可以通过硬件和软件的方式和站内自动化系统进行联动，应能实现用户自定义的设备联动，包括现场设备操作联动视频、综合自动化系统告警联动门禁视频等。并可以根据变电站现场需求，完成自动的闭环控制和告警，如自动启动/关闭空调、自动启动/关闭风机、自动启动/关闭排水系统等；'},
            {tit: null, con: '（8） 系统所有操作以及报警确认，都保留详细的日志并生成相应报表。'},
            {tit: null, con: '', url: require('../assets/images/product/15.png')},
          ]},
        ],
        data6: [
          {title: null, content: [
            {tit: null, con: '截至2022年3月底，全国机动车保有量达4.02亿辆，汽车保有量达3.07亿辆，全国新能源汽车保有量达891.5万辆，占汽车总量的2.90%。其中纯电动汽车保有量724.5万辆，占新能源汽车总量的81.27%。'},
            {tit: null, con: '未来5-8年内，保守估计将有3亿辆电动车上市，而且纯电车的比例逐步将达到100%，充电桩的需求预计在2亿只，按照每只7.5KW的功率，全部安装需要新增15亿KW的容量，而且还会多出来2亿根表前线。而截至2021年底，全国发电装机容量23.77亿千瓦（数据摘自人民日报）。可想而知，这将对电网造成巨大且不可逆的冲击。如果不适度的进行电能调配，主动干预，或者只是盲目的上容量，既不安全，又不经济。'},
            {tit: null, con: '我们的设备能够有效提升电网的向充电桩输电的效率，在同等负载的情况下，直接输出功率能够提高10%；同时按照现有技术导则计算，同等负载条件下至少可以多安装2倍以上充电桩。能够实现电力公司，物业，电动车业主的多赢局面，同时能够有帮助电动车的更快速的推广，助力早日达成“双碳”目标。'},
            {tit: null, con: '通过使用我们的设备，能够大大的延缓电网高峰时期的负载，对电网也有巨大的好处，我们的这种控制系统，是按每把枪进行销售和服务的，而且主动预警更是可以转化为长期的有偿服务，可以为项目带来非常好的盈利前景。'},
            {tit: null, con: '', url: require('../assets/images/product/16.png')},
          ]},
        ],
        imgBannerObj: {
          url: require('../assets/images/product/主图.png')
        }
      }
    },
    watch: {
      $route () {
        this.getEditableTabsValue()
      }
    },
    created () {
      this.getEditableTabsValue()
    },
    methods: {
      getEditableTabsValue () {
        switch (this.$route.query.id) {
          case 'wen':
            this.editableTabsValue = 'product_wen'
            break
          case 'hong':
            this.editableTabsValue = 'product_hong'
            break
          case 'zhi':
            this.editableTabsValue = 'product_zhi'
            break
          case 'ai':
            this.editableTabsValue = 'product_ai'
            break
          case 'service':
            this.editableTabsValue = 'product_service'
            break
          case 'charging':
            this.editableTabsValue = 'product_charging'
            break
          default:
            this.editableTabsValue = 'product_wen'
        }
      }
    }
  };
</script>

<style scoped>
/* .product{max-width:1920px;margin:0 auto;background-color:#ecf0f5;padding-bottom:1rem;} */
.product img{width:100%;}
/* .product .tab{font-size:1rem;margin:2rem 15%;}
.product /deep/ .el-tabs__nav{background-color:#fff;}
.product /deep/ .el-tabs--left .el-tabs__item{padding:.5rem 1rem;text-align:center !important;font-size:1rem;height:auto;line-height:auto;}
.product /deep/ .el-tabs__item.is-active{background-color:#a2bee7;color:#fff;font-weight:600;}
.product /deep/ .el-tabs__item.is-disabled{font-size:1.6rem;padding:1.4rem 2rem;background-color:#7895be !important;color:#fff;}
.product /deep/ .el-tabs--left .el-tabs__active-bar{height:0 !important;} */
.product .article {font-size:1rem;text-align: left;padding:1rem 2rem;background-color:#fff;min-height: 20rem;}
.product .article .title{font-size: 2rem; text-align:center;}
.product .article section.product_content {border-bottom:1px solid #ccc;padding: .8rem 0;}
.product .article section.product_content:last-child {border-bottom:none;}
.product .article h5{font-weight: 600;font-size: 1.2rem;padding: .2rem;}
.product .article h6{font-weight: 600;font-size: 1rem;padding: .2rem;}
.product .article p{padding: .4rem; line-height: 1.6; text-indent: 2em;}
</style>